import { InstrumentsState } from "../../types/instruments.types";
import {
  ADD_INSTRUMENT,
  ADD_INSTRUMENTS_URL,
  GETTING_INSTRUMENT,
  GETTING_INSTRUMENTS_URL,
  InstrumentsActionTypes,
  INSTRUMENTS_URL_ERROR,
  INSTRUMENT_ERROR,
  LOGOUT,
  LogoutActionTypes,
} from "../config/ActionTypes";

export const initialState: InstrumentsState = {
  gettingInstrumentsUrl: false,
  instrumentsUrl: null,
  getInstrumentsUrlErrorMessage: null,
  gettingInstrument: false,
  instrument: null,
  getInstrumentErrorMessage: null,
};

export function instrumentsReducer(
  state = initialState,
  action: InstrumentsActionTypes | LogoutActionTypes
): InstrumentsState {
  switch (action.type) {
    case GETTING_INSTRUMENTS_URL:
      return {
        ...state,
        gettingInstrumentsUrl: true,
        instrumentsUrl: null,
        getInstrumentsUrlErrorMessage: null,
      };

    case ADD_INSTRUMENTS_URL:
      return {
        ...state,
        gettingInstrumentsUrl: false,
        instrumentsUrl: action.url,
        getInstrumentsUrlErrorMessage: null,
      };

    case INSTRUMENTS_URL_ERROR:
      return {
        ...state,
        gettingInstrumentsUrl: false,
        instrumentsUrl: null,
        getInstrumentsUrlErrorMessage: action.error,
      };

    case GETTING_INSTRUMENT:
      return {
        ...state,
        gettingInstrument: true,
        instrument: null,
        getInstrumentErrorMessage: null,
      };

    case ADD_INSTRUMENT:
      return {
        ...state,
        gettingInstrument: false,
        instrument: action.instrument,
        getInstrumentErrorMessage: null,
      };

    case INSTRUMENT_ERROR:
      return {
        ...state,
        gettingInstrument: false,
        instrument: null,
        getInstrumentErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
