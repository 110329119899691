import React, { useEffect, useReducer, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { updateConfigurations } from "../../store/action_creators/configurations.actions";
import { AppActions } from "../../store/config/ActionTypes";
import { Configuration as ConfigurationObject, ConfigurationsRequest } from "../../types/configurations.types";
import { RootState } from "../../types/types";
import { initialState, configurationFormReducer, CHANGE_TEXT_ONE, CHANGE_TEXT_TWO } from "./reducer";

const mapStateToProps = (state: RootState) => ({
  configurations: state.configurations,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  updateConfigurations: (configurations: ConfigurationsRequest) => dispatch(updateConfigurations(configurations)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Configuration({ configurations, updateConfigurations }: PropsFromRedux) {
  const [initialized, setInitialized] = useState<boolean>(false);
  const [state, dispatch] = useReducer(configurationFormReducer, initialState);

  const saveChanges = () => {
    const configurationsList: ConfigurationObject[] = [
      {
        id: "1",
        value: state.textOne,
      },
      {
        id: "2",
        value: state.textTwo,
      },
    ];

    const configurationRequest: ConfigurationsRequest = {
      textConfigurations: configurationsList,
    };

    updateConfigurations(configurationRequest);
  };

  useEffect(() => {
    if (!initialized && configurations.configurations) {
      setInitialized(true);

      const queryOne = configurations.configurations.filter((t) => t.id === "1");
      const queryTwo = configurations.configurations.filter((t) => t.id === "2");

      if (queryOne.length === 1) {
        dispatch({
          type: CHANGE_TEXT_ONE,
          textOne: queryOne[0].value,
        });
      }

      if (queryTwo.length === 1) {
        dispatch({
          type: CHANGE_TEXT_TWO,
          textTwo: queryTwo[0].value,
        });
      }
    }
  }, [initialized, setInitialized, configurations.configurations]);

  const changeTextOne = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    dispatch({
      type: CHANGE_TEXT_ONE,
      textOne: event.target.value,
    });
  };

  const changeTextTwo = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    dispatch({
      type: CHANGE_TEXT_TWO,
      textTwo: event.target.value,
    });
  };

  return (
    <div className="configuration">
      <h1 className="title">Configuraciones</h1>
      <div className="configurations-form">
        <div className="field">
          <div className="control">
            <textarea
              className="textarea"
              value={state.textOne}
              onChange={changeTextOne}
              placeholder="Texto de inicio"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <textarea
              className="textarea"
              value={state.textTwo}
              onChange={changeTextTwo}
              placeholder="Texto de agradecimiento"
            />
          </div>
        </div>
        <div className="actions">
          <div className="control">
            <button onClick={saveChanges} className="button is-link is-medium">
              Guardar cambios
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connector(Configuration);
