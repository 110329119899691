import { get } from "./base.service";
import { BASE_URL } from "./config";
import { SupportedIssuersResponse } from "../types/supportedIssuers.types";

export const supportedIssuersService = {
  fetchSupportedIssuers,
};

async function fetchSupportedIssuers(): Promise<SupportedIssuersResponse | undefined> {
  return await get<SupportedIssuersResponse>(`${BASE_URL}/issuer`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
