import { get, post } from "./base.service";
import { BASE_URL } from "./config";
import { ConfigurationsRequest, ConfigurationsResponse } from "../types/configurations.types";
import { BaseResponse } from "../types/base.types";

export const configurationsService = {
  getConfigurations,
  updateConfigurations,
};

async function getConfigurations(): Promise<ConfigurationsResponse | undefined> {
  return await get<ConfigurationsResponse>(`${BASE_URL}/frontend`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function updateConfigurations(configurations: ConfigurationsRequest): Promise<BaseResponse | undefined> {
  return await post<BaseResponse>(`${BASE_URL}/frontend`, configurations)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
