import {
  ADD_CONFIGURATIONS,
  ConfigurationsActionTypes,
  CONFIGURATIONS_ERROR,
  GETTING_CONFIGURATIONS,
  LOGOUT,
  LogoutActionTypes,
  UPDATE_CONFIGURATIONS,
  UPDATE_CONFIGURATIONS_ERROR,
  UPDATING_CONFIGURATIONS,
} from "../config/ActionTypes";
import { ConfigurationsState } from "../../types/configurations.types";

export const initialState: ConfigurationsState = {
  gettingConfigurations: false,
  configurations: null,
  getConfigurationsErrorMessage: null,
  updatingConfigurations: false,
  updateConfigurationsSuccess: false,
  updateConfigurationsErrorMessage: null,
};

export function configurationsReducer(
  state = initialState,
  action: ConfigurationsActionTypes | LogoutActionTypes
): ConfigurationsState {
  switch (action.type) {
    case GETTING_CONFIGURATIONS:
      return {
        ...state,
        gettingConfigurations: true,
        configurations: null,
        getConfigurationsErrorMessage: null,
      };

    case ADD_CONFIGURATIONS:
      return {
        ...state,
        gettingConfigurations: false,
        configurations: action.texts,
        getConfigurationsErrorMessage: null,
      };

    case CONFIGURATIONS_ERROR:
      return {
        ...state,
        gettingConfigurations: false,
        configurations: null,
        getConfigurationsErrorMessage: action.error,
      };

    case UPDATING_CONFIGURATIONS:
      return {
        ...state,
        updatingConfigurations: true,
        updateConfigurationsSuccess: false,
        updateConfigurationsErrorMessage: null,
      };

    case UPDATE_CONFIGURATIONS:
      return {
        ...state,
        updatingConfigurations: false,
        configurations: action.texts,
        updateConfigurationsSuccess: true,
        updateConfigurationsErrorMessage: null,
      };

    case UPDATE_CONFIGURATIONS_ERROR:
      return {
        ...state,
        updatingConfigurations: false,
        updateConfigurationsSuccess: false,
        updateConfigurationsErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
