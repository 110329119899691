import { BASE_URL } from "../services/config";

export const urlHelper = {
  parseQueryString,
  isPublicPage,
  isPrivatePage,
  getContentClass,
  getHubUrl,
};

function parseQueryString(queryString?: string): any {
  if (!queryString) {
    queryString = window.location.search.substring(1);
  }

  const params: any = {};

  const queries = queryString.split("&");

  queries.forEach((indexQuery: string) => {
    const indexPair = indexQuery.split("=");

    const queryKey = indexPair[0];
    const queryValue = indexPair.length > 1 ? indexPair[1] : "";

    params[queryKey] = queryValue;
  });

  return params;
}

const publicPages = ["/", "/login", "/plexo", "/result", "/thanks", "/redirect"];

const privatePages = ["/configuration", "/users"];

function isPublicPage(url: string): boolean {
  return publicPages.indexOf(url) > -1;
}

function isPrivatePage(url: string): boolean {
  return privatePages.indexOf(url) > -1;
}

function getContentClass(url: string): string {
  if (url === "/login") {
    return "login-content";
  }

  return isPrivatePage(url) ? "private-content" : "content";
}

function getHubUrl() {
  return `${BASE_URL}/suscritonHub`;
}
