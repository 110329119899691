import { useState } from "react";
import { useHistory } from "react-router";
import logo from "../assets/logo.png";
import Cookies from "universal-cookie";

interface HeaderProps {
  isLoginPage: boolean;
}

function Header({ isLoginPage }: HeaderProps) {
  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);

  const goHome = () => {
    history.push("/landing");
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove("token");
    history.push("/login");
    closeDialog();
  };

  return (
    <>
      <div className="header">
        <div className="header-content">
          <div className="left-side">
            <img onClick={goHome} src={logo} alt="Logo de Teletón" />
          </div>
          {!isLoginPage && (
            <div className="header-options">
              <span className="option" onClick={() => history.push("/users")}>
                Usuarios
              </span>
              <span className="option" onClick={() => history.push("/configuration")}>
                Configuración
              </span>
              <span className="option" onClick={() => setOpen(true)}>
                Salir
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={`modal ${open ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Cerrar sesión</p>
            <button className="delete" onClick={closeDialog}></button>
          </header>
          <section className="modal-card-body">Seguro que quieres cerrar sesión?</section>
          <footer className="modal-card-foot">
            <button className="button is-primary" onClick={logout}>
              Cerrar sesión
            </button>
            <button className="button" onClick={closeDialog}>
              Cancelar
            </button>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Header;
