import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { fetchSubscriptors } from "../../store/action_creators/subscriptors.actions";
import { AppActions } from "../../store/config/ActionTypes";
import { RootState } from "../../types/types";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { xlsHelper } from "../../helpers/xlsHelper";
import { getGenderString } from "../../types/subscriptors.types";

const mapStateToProps = (state: RootState) => ({
  subscriptors: state.subscriptors,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchSubscriptors: () => dispatch(fetchSubscriptors()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Users({ subscriptors, fetchSubscriptors }: PropsFromRedux) {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    if (
      !subscriptors.gettingSubscriptors &&
      subscriptors.subscriptors === null &&
      subscriptors.subscriptorsErrorMessage === null
    ) {
      fetchSubscriptors();
    }
  }, [
    subscriptors.gettingSubscriptors,
    subscriptors.subscriptors,
    subscriptors.subscriptorsErrorMessage,
    fetchSubscriptors,
  ]);

  const exportUsers = () => {
    exportToCSV(xlsHelper.getUsersXLS(subscriptors.subscriptors!), "Test");
  };

  return (
    <div className="users">
      <div className="users-header">
        <h1 className="title">Listado de usuarios</h1>
        <button onClick={exportUsers} className="button is-link">
          Exportar a Excel
        </button>
      </div>
      <table className="table is-striped">
        <thead>
          <tr>
            <th>Id externo</th>
            <th>Nombre</th>
            <th>Email</th>
            <th>Fecha de nacimiento</th>
            <th>Celular</th>
            <th>Ciudad</th>
            <th>Género</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          {subscriptors.subscriptors &&
            subscriptors.subscriptors.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{user.id ? user.id : "-"}</td>
                  <td>{user.names ? user.names : "-"} {user.surnames ? user.surnames : ""}</td>
                  <td>{user.email ? user.email : "-"}</td>
                  <td>
                    {new Date(user.birthDate).toLocaleDateString() !== "1/1/1"
                      ? new Date(user.birthDate).toLocaleDateString()
                      : "-"}
                  </td>
                  <td>{user.telephoneNo ? user.telephoneNo : "-"}</td>
                  <td>{user.city ? user.city : "-"}</td>
                  <td>{getGenderString(user.gender)}</td>
                  <td>{user.amount ? user.amount : "-"}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default connector(Users);
