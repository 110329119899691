import { Dispatch } from "redux";
import { instrumentsService } from "../../services/instruments.service";
import { Instrument, InstrumentsUrlRequest } from "../../types/instruments.types";
import {
  ADD_INSTRUMENTS_URL,
  GetInstrumentsUrlActionTypes,
  GETTING_INSTRUMENTS_URL,
  INSTRUMENTS_URL_ERROR,
  GETTING_INSTRUMENT,
  ADD_INSTRUMENT,
  INSTRUMENT_ERROR,
  GetInstrumentActionTypes,
} from "../config/ActionTypes";

//#region Get instrument URL

export const getInstrumentsUrl =
  (instrumentsUrlRequest: InstrumentsUrlRequest) => (dispatch: Dispatch<GetInstrumentsUrlActionTypes>) => {
    dispatch(gettingInstrumentsUrl());

    return instrumentsService.getInstrumentsUrl(instrumentsUrlRequest).then(
      (response) => {
        dispatch(addInstrumentsUrl(response!.url));
      },
      (error) => {
        dispatch(getInstrumentsUrlError(error));
      }
    );
  };

const gettingInstrumentsUrl = (): GetInstrumentsUrlActionTypes => ({
  type: GETTING_INSTRUMENTS_URL,
});

const addInstrumentsUrl = (url: string): GetInstrumentsUrlActionTypes => ({
  type: ADD_INSTRUMENTS_URL,
  url,
});

const getInstrumentsUrlError = (error: string): GetInstrumentsUrlActionTypes => ({
  type: INSTRUMENTS_URL_ERROR,
  error,
});

//#endregion

//#region Get instrument

export const getInstrument = () => (dispatch: Dispatch<GetInstrumentActionTypes>) => {
  dispatch(gettingInstrument());

  return instrumentsService.getInstrument().then(
    (response) => {
      dispatch(addInstrument(response!));
    },
    (error) => {
      dispatch(getInstrumentError(error));
    }
  );
};

const gettingInstrument = (): GetInstrumentActionTypes => ({
  type: GETTING_INSTRUMENT,
});

const addInstrument = (instrument: Instrument): GetInstrumentActionTypes => ({
  type: ADD_INSTRUMENT,
  instrument,
});

const getInstrumentError = (error: string): GetInstrumentActionTypes => ({
  type: INSTRUMENT_ERROR,
  error,
});

//#endregion
