import { SupportedIssuersState } from "../../types/supportedIssuers.types";
import {
  GETTING_SUPPORTED_ISSUERS,
  ADD_SUPPORTED_ISSUERS,
  SUPPORTED_ISSUERS_ERROR,
  GetSupportedIssuersActionTypes
} from "../config/ActionTypes";

export const initialState: SupportedIssuersState = {
  gettingSupportedIssuers: false,
  supportedIssuers: null,
  supportedIssuersErrorMessage: null,
};

export function supportedIssuersReducer(
  state = initialState,
  action: GetSupportedIssuersActionTypes): SupportedIssuersState {
  switch (action.type) {
    case GETTING_SUPPORTED_ISSUERS:
      return {
        ...state,
        gettingSupportedIssuers: true,
        supportedIssuers: null,
        supportedIssuersErrorMessage: null,
      };

    case ADD_SUPPORTED_ISSUERS:
      return {
        ...state,
        gettingSupportedIssuers: false,
        supportedIssuers: action.supportedIssuers,
        supportedIssuersErrorMessage: null,
      };

    case SUPPORTED_ISSUERS_ERROR:
      return {
        ...state,
        gettingSupportedIssuers: false,
        supportedIssuers: null,
        supportedIssuersErrorMessage: action.error,
      };

    default:
      return state;
  }
}
