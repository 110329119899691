import { Dispatch } from "redux";
import { configurationsService } from "../../services/configurations.service";
import { Configuration, ConfigurationsRequest } from "../../types/configurations.types";
import {
  GetConfigurationsActionTypes,
  GETTING_CONFIGURATIONS,
  ADD_CONFIGURATIONS,
  CONFIGURATIONS_ERROR,
  UpdateConfigurationsActionTypes,
  UPDATING_CONFIGURATIONS,
  UPDATE_CONFIGURATIONS,
  UPDATE_CONFIGURATIONS_ERROR,
} from "../config/ActionTypes";

//#region  Get configurations

export const getConfigurations = () => (dispatch: Dispatch<GetConfigurationsActionTypes>) => {
  dispatch(gettingConfigurations());

  return configurationsService.getConfigurations().then(
    (response) => {
      dispatch(addConfigurations(response ? response.texts : []));
    },
    (error) => {
      dispatch(getConfigurationsError(error));
    }
  );
};

const gettingConfigurations = (): GetConfigurationsActionTypes => ({
  type: GETTING_CONFIGURATIONS,
});

const addConfigurations = (texts: Configuration[]): GetConfigurationsActionTypes => ({
  type: ADD_CONFIGURATIONS,
  texts,
});

const getConfigurationsError = (error: string): GetConfigurationsActionTypes => ({
  type: CONFIGURATIONS_ERROR,
  error,
});

//#endregion

//#region  Update configurations

export const updateConfigurations =
  (configurations: ConfigurationsRequest) => (dispatch: Dispatch<UpdateConfigurationsActionTypes>) => {
    dispatch(updatingConfigurations());

    return configurationsService.updateConfigurations(configurations).then(
      (response) => {
        dispatch(updateConfigurationsSuccess(configurations.textConfigurations));
      },
      (error) => {
        dispatch(updateConfigurationsError(error));
      }
    );
  };

const updatingConfigurations = (): UpdateConfigurationsActionTypes => ({
  type: UPDATING_CONFIGURATIONS,
});

const updateConfigurationsSuccess = (texts: Configuration[]): UpdateConfigurationsActionTypes => ({
  type: UPDATE_CONFIGURATIONS,
  texts,
});

const updateConfigurationsError = (error: string): UpdateConfigurationsActionTypes => ({
  type: UPDATE_CONFIGURATIONS_ERROR,
  error,
});

//#endregion
