import { useEffect, useState } from "react";
import { urlHelper } from "../../helpers/urlHelper";
import Loading from "../../components/Loading";
import Subscribe from "../../components/Subscribe";

function Redirect() {
  const isOnIframe = window.location !== window.parent.location;
  let urlVars = urlHelper.parseQueryString();

  const [mustSubscribe, setMustSubscribe] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(true);

      if (isOnIframe) {
        const object = {
          message: urlVars.errorcode || urlVars.errormessage ? "redirectError" : "redirectSuccess",
          payload: urlVars.errorcode || urlVars.errormessage ? urlVars.errorcode : urlVars,
        };
        window.parent.postMessage(object, `${window.location.origin}/redirect`);
      } else {
        setMustSubscribe(true);
      }
    }
  }, [urlVars, loaded, setLoaded, isOnIframe, setMustSubscribe]);

  return (
    <div className="loading">
      <Subscribe
        mustSubscribe={mustSubscribe}
        externalId={urlVars.externalId}
        subscriptionTypeId={urlVars.subscriptionTypeId}
      />
      <Loading />
    </div>
  );
}

export default Redirect;
