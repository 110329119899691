import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import blackLogo from "../../assets/blackLogo.png";
import sociosOnlineLogo from "../../assets/sociosonline.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import DonationOption from "./DonationOption";
import { RootState } from "../../types/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { fetchSubscriptionTypes, selectSubscriptionType } from "../../store/action_creators/subscriptionTypes.actions";
import { getInstrumentsUrl } from "../../store/action_creators/instruments.actions";
import { connect, ConnectedProps } from "react-redux";
import { InstrumentsUrlRequest } from "../../types/instruments.types";
import { SubscriptionType } from "../../types/subscriptionTypes.types";
import Loading from "../../components/Loading";
import SupportedIssuers from "../../components/SupportedIssuers";

const mapStateToProps = (state: RootState) => ({
  configurations: state.configurations,
  subscriptionTypes: state.subscriptionTypes,
  instruments: state.instruments,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchSubscriptionTypes: () => dispatch(fetchSubscriptionTypes()),
  selectSubscriptionType: (subscriptionType: SubscriptionType) => dispatch(selectSubscriptionType(subscriptionType)),
  getInstrumentsUrl: (instrumentsUrlRequest: InstrumentsUrlRequest) =>
    dispatch(getInstrumentsUrl(instrumentsUrlRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Landing({
  configurations,
  subscriptionTypes,
  instruments,
  fetchSubscriptionTypes,
  getInstrumentsUrl,
  selectSubscriptionType,
}: PropsFromRedux) {
  const history = useHistory();
  const [seeMore, setSeeMore] = useState<boolean>(false);
  const [finishedTransition, setFinishedTransition] = useState<boolean>(true);

  useEffect(() => {
    if (
      !subscriptionTypes.gettingSubscriptionTypes &&
      subscriptionTypes.subscriptionTypes === null &&
      subscriptionTypes.subscriptionTypesErrorMessage === null
    ) {
      fetchSubscriptionTypes();
    }
  }, [
    subscriptionTypes.gettingSubscriptionTypes,
    subscriptionTypes.subscriptionTypes,
    subscriptionTypes.subscriptionTypesErrorMessage,
    fetchSubscriptionTypes,
  ]);

  const onClickSeeMore = () => {
    setFinishedTransition(false);
    const newState = !seeMore;
    setSeeMore(newState);

    if (newState) {
      setFinishedTransition(true);
    } else {
      setTimeout(() => {
        setFinishedTransition(true);
      }, 900);
    }
  };

  const isFinishingTransition = (!seeMore && !finishedTransition) || (seeMore && finishedTransition);

  const onOptionClick = (subscriptionType: SubscriptionType) => {
    if (!instruments.gettingInstrumentsUrl) {
      const request: InstrumentsUrlRequest = {
        callbackUrl: `${window.location.origin}/redirect?subscriptionTypeId=${subscriptionType.id}`,
      };

      getInstrumentsUrl(request);
    }

    selectSubscriptionType(subscriptionType);
    history.push("/plexo");
  };

  const hiddenTypes: number[] = [75, 150, 750, 1500, 2000];

  const isVisible = (subscriptionType: SubscriptionType) => {
    return hiddenTypes.indexOf(subscriptionType.subscriptionBaseCost) === -1;
  };

  return (
    <div className="landing">
      <div className="head">
        <img className="main-logo" src={sociosOnlineLogo} alt="Logo de Socios Online" />
        <img className="secondary-logo" src={blackLogo} alt="Logo de Teletón" />
        <h1 className="title">¡Bienvenido!</h1>
      </div>
      {configurations.configurations && configurations.configurations.filter((c) => c.id === "1").length > 0 && (
        configurations.configurations.filter((c) => c.id === "1")[0].value.split("\n").map(text => {
          return (<p className="plain-text subtitle">{text}</p>)
        })
      )}
      {/* <a href="/localhost">Quieres donar por única vez? Haz click aquí</a> */}
      {subscriptionTypes.subscriptionTypes ? (
        <>
          <div className="columns">
            {subscriptionTypes.subscriptionTypes &&
              subscriptionTypes.subscriptionTypes.map((subscriptionType) => {
                if (isVisible(subscriptionType))
                  return (
                    <DonationOption
                      key={subscriptionType.id}
                      subscriptionType={subscriptionType}
                      onOptionClick={onOptionClick}
                    />
                  );
                return null;
              })}
          </div>
          <div className="view-more">
            <div />
            <span onClick={onClickSeeMore}>
              Ver más opciones <FontAwesomeIcon className={`icon ${seeMore ? "rotate" : ""}`} icon={faChevronRight} />
            </span>
            <div />
          </div>
          {isFinishingTransition && (
            <div className={`columns ${seeMore ? "fade-in" : "fade-out"}`}>
              {subscriptionTypes.subscriptionTypes &&
                subscriptionTypes.subscriptionTypes.map((subscriptionType) => {
                  if (!isVisible(subscriptionType))
                    return (
                      <DonationOption
                        key={subscriptionType.id}
                        subscriptionType={subscriptionType}
                        onOptionClick={onOptionClick}
                      />
                    );
                  return null;
                })}
            </div>
          )}
        </>
      ) : (
        <div className="loading">
          <Loading />
        </div>
      )}
      <SupportedIssuers />
    </div>
  );
}

export default connector(Landing);
