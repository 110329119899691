import { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import sociosOnlineLogo from "../../assets/sociosonline.png";
import blackLogo from "../../assets/blackLogo.png";
import Subscribe from "../../components/Subscribe";
import { AppActions } from "../../store/config/ActionTypes";
import { RootState } from "../../types/types";

declare global {
  interface Window {
    attachEvent: any;
  }
}

window.attachEvent = window.attachEvent || {};

const mapStateToProps = (state: RootState) => ({
  subscriptionTypes: state.subscriptionTypes,
  instruments: state.instruments,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function PlexoPayment({ subscriptionTypes, instruments }: PropsFromRedux) {
  const history = useHistory();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [externalId, setExternalId] = useState<string>("");
  const [subscriptionTypeId, setSubscriptionTypeId] = useState<string>("");
  const [mustSubscribe, setMustSubscribe] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("message", receiveMessage);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
  });

  function receiveMessage(e: MessageEventInit) {
    if (e.data.message === "redirectError") {
      // history.push(`/result?errorCode=${e.data.payload}`);
    } else if (e.data.message === "redirectSuccess") {
      setExternalId(e.data.payload.externalId);
      setSubscriptionTypeId(e.data.payload.subscriptionTypeId);
      setMustSubscribe(true);
    }
  }

  // function onMessage(event: MessageEventInit) {
  //   var data = event.data;
  //   if (data && data.plexo && iframeRef && iframeRef.current) {
  //     iframeRef.current.height = data.plexo.height;
  //   }
  // }

  // useEffect(() => {
  //   if (window.addEventListener) {
  //     window.addEventListener("message", onMessage, false);
  //   } else if (window.attachEvent) {
  //     window.attachEvent("onmessage", onMessage, false);
  //   }

  //   return () => {
  //     window.removeEventListener("message", onMessage, false);
  //   };
  // });

  useEffect(() => {
    if (subscriptionTypes.selectedSubscriptionType === null) {
      history.push("/landing");
    }
  });

  return (
    <div className="columns plexo">
      <div className="column info">
        <div className="logos">
          <img className="socios-online" src={sociosOnlineLogo} alt="Logo de Socios Online" />
          <img className="teleton" src={blackLogo} alt="Logo de Teletón" />
        </div>
        <div className="amount">
          <p>Monto seleccionado</p>
          <h1>{`$${subscriptionTypes.selectedSubscriptionType?.subscriptionBaseCost}`}</h1>
        </div>
        <a href="/landing">Quieres elegir un monto distinto? Haz click aquí</a>
      </div>
      <div className="column">
        <Subscribe mustSubscribe={mustSubscribe} externalId={externalId} subscriptionTypeId={subscriptionTypeId} />
        <iframe
          id="plexoIframe"
          ref={iframeRef}
          height={600}
          width={498}
          src={instruments.instrumentsUrl ? instruments.instrumentsUrl : `${window.location.origin}/loading`}
          title="plexo"
        />
      </div>
    </div>
  );
}

export default connector(PlexoPayment);
