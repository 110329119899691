import { Gender } from "../../types/subscriptors.types";

export const CHANGE_NAME = "CHANGE_NAME";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_DATE = "CHANGE_DATE";
export const CHANGE_PHONE = "CHANGE_PHONE";
export const CHANGE_CITY = "CHANGE_CITY";
export const CHANGE_GENDER = "CHANGE_GENDER";

interface ChangeNameAction {
  type: typeof CHANGE_NAME;
  name: string;
}

interface ChangeEmailAction {
  type: typeof CHANGE_EMAIL;
  email: string;
}

interface ChangeDateAction {
  type: typeof CHANGE_DATE;
  date: string;
}

interface ChangePhoneAction {
  type: typeof CHANGE_PHONE;
  phone: string;
}

interface ChangeCityAction {
  type: typeof CHANGE_CITY;
  city: City;
}

interface ChangeGenderAction {
  type: typeof CHANGE_GENDER;
  gender: Gender;
}

type UserFormActions =
  | ChangeNameAction
  | ChangeEmailAction
  | ChangeDateAction
  | ChangePhoneAction
  | ChangeCityAction
  | ChangeGenderAction;

export enum City {
  None,
  Artigas,
  Canelones,
  CerroLargo,
  Colonia,
  Durazno,
  Flores,
  Florida,
  Lavalleja,
  Maldonado,
  Montevideo,
  Paysandu,
  RioNegro,
  Rivera,
  Rocha,
  Salto,
  SanJose,
  Soriano,
  Tacuarembo,
  TreintaYTres,
}

export function getCityString(city: City) {
  switch (city) {
    case City.None:
      return "Departamento";

    case City.Artigas:
      return "Artigas";

    case City.Canelones:
      return "Canelones";

    case City.CerroLargo:
      return "Cerro Largo";

    case City.Colonia:
      return "Colonia";

    case City.Durazno:
      return "Durazno";

    case City.Flores:
      return "Flores";

    case City.Florida:
      return "Florida";

    case City.Lavalleja:
      return "Lavalleja";

    case City.Maldonado:
      return "Maldonado";

    case City.Montevideo:
      return "Montevideo";

    case City.Paysandu:
      return "Paysandú";

    case City.RioNegro:
      return "Rio Negro";

    case City.Rivera:
      return "Rivera";

    case City.Rocha:
      return "Rocha";

    case City.Salto:
      return "Salto";

    case City.SanJose:
      return "San José";

    case City.Soriano:
      return "Soriano";

    case City.Tacuarembo:
      return "Tacuarembó";

    case City.TreintaYTres:
      return "Treinta Y Tres";

    default:
      return City;
  }
}

interface UserFormState {
  name: string;
  email: string;
  date: string;
  phone: string;
  city: City;
  gender: Gender;
}

export const initialState: UserFormState = {
  name: "",
  email: "",
  date: new Date().toString(),
  phone: "",
  city: City.None,
  gender: Gender.Unanswered,
};

export function userFormReducer(state: UserFormState, action: UserFormActions) {
  switch (action.type) {
    case CHANGE_NAME: {
      return {
        ...state,
        name: action.name,
      };
    }

    case CHANGE_EMAIL: {
      return {
        ...state,
        email: action.email,
      };
    }

    case CHANGE_DATE: {
      return {
        ...state,
        date: action.date,
      };
    }

    case CHANGE_PHONE: {
      return {
        ...state,
        phone: action.phone,
      };
    }

    case CHANGE_CITY: {
      return {
        ...state,
        city: action.city,
      };
    }

    case CHANGE_GENDER: {
      return {
        ...state,
        gender: action.gender,
      };
    }

    default:
      throw new Error();
  }
}
