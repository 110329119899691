import { Dispatch } from "redux";
import { subscriptorsService } from "../../services/subscriptors.service";
import {
  CreateSubscriptorRequest,
  SubscribeRequest,
  Subscriptor,
  SubscriptorData,
  UserDataObject,
} from "../../types/subscriptors.types";
import {
  ADDING_SUBSCRIPTOR_DATA,
  AddSubscriptionDataActionTypes,
  ADD_SUBSCRIPTORS,
  ADD_SUBSCRIPTOR_DATA_ERROR,
  ADD_SUBSCRIPTOR_DATA_SUCCESS,
  ADD_USER_FIELDS,
  CHANGE_PAYMENT_METHOD,
  CreateSubscriptorActionTypes,
  CREATE_SUBSCRIPTOR_ERROR,
  CREATE_SUBSCRIPTOR_SUCCESS,
  CREATING_SUBSCRIPTOR,
  GetSubscriptorsActionTypes,
  GETTING_SUBSCRIPTORS,
  RETRY_SUBSCRIPTION,
  SubscribeActionTypes,
  SUBSCRIBE_ERROR,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBING,
  SUBSCRIPTORS_ERROR,
} from "../config/ActionTypes";

//#region Create subscriptor

export const createSubscriptor =
  (createSubscriptorRequest: CreateSubscriptorRequest) => (dispatch: Dispatch<CreateSubscriptorActionTypes>) => {
    dispatch(creatingSubscriptor());

    return subscriptorsService.createSubscriptor(createSubscriptorRequest).then(
      (response) => {
        dispatch(createSubscriptorSuccess());
      },
      (error) => {
        dispatch(createSubscriptorError(error));
      }
    );
  };

const creatingSubscriptor = (): CreateSubscriptorActionTypes => ({
  type: CREATING_SUBSCRIPTOR,
});

const createSubscriptorSuccess = (): CreateSubscriptorActionTypes => ({
  type: CREATE_SUBSCRIPTOR_SUCCESS,
});

const createSubscriptorError = (error: string): CreateSubscriptorActionTypes => ({
  type: CREATE_SUBSCRIPTOR_ERROR,
  error,
});

//#endregion

//#region Subscribe

export const subscribe = (subscribeRequest: SubscribeRequest) => (dispatch: Dispatch<SubscribeActionTypes>) => {
  dispatch(subscribing(subscribeRequest));

  return subscriptorsService.subscribe(subscribeRequest).then(
    (response) => {
      dispatch(subscribeSuccess());
    },
    (error) => {
      dispatch(subscribeError(error));
    }
  );
};

const subscribing = (subscribeRequest: SubscribeRequest): SubscribeActionTypes => ({
  type: SUBSCRIBING,
  subscribeRequest,
});

const subscribeSuccess = (): SubscribeActionTypes => ({
  type: SUBSCRIBE_SUCCESS,
});

const subscribeError = (error: string): SubscribeActionTypes => ({
  type: SUBSCRIBE_ERROR,
  error,
});

export const retrySubscription = (): SubscribeActionTypes => ({
  type: RETRY_SUBSCRIPTION,
});

export const changePaymentMethod = (): SubscribeActionTypes => ({
  type: CHANGE_PAYMENT_METHOD,
});

//#endregion

//#region Fetch subscriptors

export const fetchSubscriptors = () => (dispatch: Dispatch<GetSubscriptorsActionTypes>) => {
  dispatch(gettingSubscriptors());

  return subscriptorsService.fetchSubscriptors().then(
    (response) => {
      dispatch(addSubscriptors(response ? response!.subscriptors : []));
    },
    (error) => {
      dispatch(subscriptorsError(error));
    }
  );
};

const gettingSubscriptors = (): GetSubscriptorsActionTypes => ({
  type: GETTING_SUBSCRIPTORS,
});

const addSubscriptors = (subscriptors: Subscriptor[]): GetSubscriptorsActionTypes => ({
  type: ADD_SUBSCRIPTORS,
  subscriptors,
});

const subscriptorsError = (error: string): GetSubscriptorsActionTypes => ({
  type: SUBSCRIPTORS_ERROR,
  error,
});

//#endregion

//#region Add subscriptor data

export const addUserFields = (fields: UserDataObject[]): AddSubscriptionDataActionTypes => ({
  type: ADD_USER_FIELDS,
  fields,
});

export const addSubscriptorData =
  (subscriptorData: SubscriptorData) => (dispatch: Dispatch<AddSubscriptionDataActionTypes>) => {
    dispatch(addingSubscriptorData());

    return subscriptorsService.addSubscriptorData(subscriptorData).then(
      (response) => {
        dispatch(addSubscriptorDataSuccess());
      },
      (error) => {
        dispatch(addSubscriptorDataError(error));
      }
    );
  };

const addingSubscriptorData = (): AddSubscriptionDataActionTypes => ({
  type: ADDING_SUBSCRIPTOR_DATA,
});

const addSubscriptorDataSuccess = (): AddSubscriptionDataActionTypes => ({
  type: ADD_SUBSCRIPTOR_DATA_SUCCESS,
});

const addSubscriptorDataError = (error: string): AddSubscriptionDataActionTypes => ({
  type: ADD_SUBSCRIPTOR_DATA_ERROR,
  error,
});

//#endregion
