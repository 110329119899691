import { BaseResponse } from "./base.types";

export interface CreateSubscriptorRequest {
  externalId: string;
  email: string;
  name: string;
  fullName: string;
  postalCode: string;
}

export interface SubscribeRequest {
  subscriptionTypeId: string;
  legalId: string;
  cibersourceFingerprint: string;
  externalId: string;
}

export enum SubscriptorFieldEnum {
  ExternalId,
  SubscriptorId,
  Email,
  Names,
  Surnames,
  TelephoneNo,
  Birthdate,
  City,
  Provider,
  Gender,
}

export interface UserDataObject {
  field: SubscriptorFieldEnum;
  exist: boolean;
}

export interface SubscriptorData {
  id: string;
  names?: string | null;
  surnames?: string | null;
  email?: string | null;
  birthdate?: string | null;
  telephoneNo?: string | null;
  city?: string | null;
  gender?: Gender | null;
}

export enum Gender {
  Male = 1,
  Female = 2,
  Other = 3,
  Unanswered = 4,
}

//TODO: Move this from here
export function getGenderString(gender: Gender) {
  if (gender === Gender.Male) {
    return "Masculino";
  }

  if (gender === Gender.Female) {
    return "Femenino";
  }

  if (gender === Gender.Other) {
    return "Otro";
  }

  return "-";
}

export interface SubscriptorsResponse extends BaseResponse {
  subscriptors: Subscriptor[];
}

export interface Subscriptor {
  id: string;
  created: Date;
  lastModified: Date;
  subscriptorId: string;
  email: string;
  names: string;
  surnames: string;
  telephoneNo: string;
  birthDate: Date;
  city: number;
  gender: Gender;
  userType: number;
  amount: string | null;
}

export interface SubscriptorsState {
  creatingSubscriptor: boolean;
  createSubscriptorSuccess: boolean;
  createSubscriptorErrorMessage: string | null;
  subscribing: boolean;
  subscribeRequest: SubscribeRequest | null;
  subscribeSuccess: boolean;
  subscribeErrorMessage: string | null;
  gettingSubscriptors: boolean;
  subscriptors: Subscriptor[] | null;
  subscriptorsErrorMessage: string | null;
  userFields: UserDataObject[] | null;
  addingSubscriptorData: boolean;
  addSubscriptorDataSuccess: boolean;
  addSubscriptorDataErrorMessage: string | null;
}
