import { Dispatch } from "redux";
import Cookies from "universal-cookie";
import { authService } from "../../services/auth.service";
import { LoginRequest } from "../../types/auth.types";
import {
  LOGGING_IN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LoginActionTypes,
  LogoutActionTypes,
} from "../config/ActionTypes";

//#region Login

export const login = (loginRequest: LoginRequest) => (dispatch: Dispatch<LoginActionTypes>) => {
  dispatch(loggingIn());

  return authService.login(loginRequest).then(
    (response) => {
      const cookies = new Cookies();
      cookies.set("token", response!.token, { path: "/" });

      dispatch(loginSuccess(response!.token));
    },
    (error) => {
      dispatch(loginError(error));
    }
  );
};

const loggingIn = (): LoginActionTypes => ({
  type: LOGGING_IN,
});

const loginSuccess = (token: string): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
  token,
});

const loginError = (error: string): LoginActionTypes => ({
  type: LOGIN_ERROR,
  error,
});

//#endregion

//#region Logout

export const logout = (): LogoutActionTypes => ({
  type: LOGOUT,
});

//#endregion
