export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

interface ChangeEmailAction {
  type: typeof CHANGE_EMAIL;
  email: string;
}

interface ChangePasswordAction {
  type: typeof CHANGE_PASSWORD;
  password: string;
}

type LoginFormActions = ChangeEmailAction | ChangePasswordAction;

interface LoginFormState {
  email: string;
  password: string;
}

export const initialState: LoginFormState = {
  email: "",
  password: "",
};

export function loginFormReducer(state: LoginFormState, action: LoginFormActions) {
  switch (action.type) {
    case CHANGE_EMAIL: {
      return {
        ...state,
        email: action.email,
      };
    }

    case CHANGE_PASSWORD: {
      return {
        ...state,
        password: action.password,
      };
    }

    default:
      throw new Error();
  }
}
