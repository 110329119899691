import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { authReducer } from "../reducers/auth";
import { configurationsReducer } from "../reducers/configurations";
import { instrumentsReducer } from "../reducers/instruments";
import { subscriptionTypesReducer } from "../reducers/subscriptionTypes";
import { subscriptorsReducer } from "../reducers/subscriptors";
import { supportedIssuersReducer } from "../reducers/supportedIssuers";
import { AppActions } from "./ActionTypes";
import signalRMiddleware from "../middlewares/signalRMiddleware";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const rootReducer = combineReducers({
  auth: authReducer,
  configurations: configurationsReducer,
  instruments: instrumentsReducer,
  subscriptionTypes: subscriptionTypesReducer,
  subscriptors: subscriptorsReducer,
  supportedIssuers: supportedIssuersReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>, signalRMiddleware))
);
