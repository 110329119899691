import { Lottie } from "@crello/react-lottie";
import likeAnimation from "../../assets/suscriton-like.json";
import sociosOnlineLogo from "../../assets/sociosonline.png";
import blackLogo from "../../assets/blackLogo.png";
import { RootState } from "../../types/types";
import { connect, ConnectedProps } from "react-redux";

const mapStateToProps = (state: RootState) => ({
  configurations: state.configurations,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Thanks({ configurations }: PropsFromRedux) {
  return (
    <div className="thanks">
      <img className="socios-online" src={sociosOnlineLogo} alt="Logo de Socios Online" />
      <img className="teleton" src={blackLogo} alt="Logo de Teletón" />
      <h1 className="title">Gracias por colaborar con Teletón</h1>
      <Lottie height="10rem" width="10rem" config={{ animationData: likeAnimation, loop: true }} />
      {configurations.configurations && configurations.configurations.filter((c) => c.id === "2").length > 0 && (
        <p className="plain-text">{configurations.configurations.filter((c) => c.id === "2")[0].value}</p>
      )}
    </div>
  );
}

export default connector(Thanks);
