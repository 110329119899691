import { Instrument } from "../../types/instruments.types";
import { SubscriptionType } from "../../types/subscriptionTypes.types";
import { SubscribeRequest, Subscriptor, UserDataObject } from "../../types/subscriptors.types";
import { Configuration } from "../../types/configurations.types";
import { Issuer } from "../../types/supportedIssuers.types";

//#region Auth actions

export const LOGGING_IN = "LOGGING_IN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export interface LoggingInAction {
  type: typeof LOGGING_IN;
}

export interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS;
  token: string;
}

export interface LoginErrorAction {
  type: typeof LOGIN_ERROR;
  error: string;
}

export type LoginActionTypes = LoggingInAction | LoginSuccessAction | LoginErrorAction;

export const LOGOUT = "LOGOUT";

export interface LogoutAction {
  type: typeof LOGOUT;
}

export type LogoutActionTypes = LogoutAction;

export type AuthActionTypes = LoginActionTypes | LogoutActionTypes;

//#endregion

//#region Configurations actions

export const GETTING_CONFIGURATIONS = "GETTING_CONFIGURATIONS";
export const ADD_CONFIGURATIONS = "ADD_CONFIGURATIONS";
export const CONFIGURATIONS_ERROR = "CONFIGURATIONS_ERROR";

export interface GettingConfigurationsAction {
  type: typeof GETTING_CONFIGURATIONS;
}

export interface AddConfigurationsAction {
  type: typeof ADD_CONFIGURATIONS;
  texts: Configuration[];
}

export interface GetConfigurationsErrorAction {
  type: typeof CONFIGURATIONS_ERROR;
  error: string;
}

export type GetConfigurationsActionTypes =
  | GettingConfigurationsAction
  | AddConfigurationsAction
  | GetConfigurationsErrorAction;

export const UPDATING_CONFIGURATIONS = "UPDATING_CONFIGURATIONS";
export const UPDATE_CONFIGURATIONS = "UPDATE_CONFIGURATIONS";
export const UPDATE_CONFIGURATIONS_ERROR = "UPDATE_CONFIGURATIONS_ERROR";

export interface UpdatingConfigurationsAction {
  type: typeof UPDATING_CONFIGURATIONS;
}

export interface UpdateConfigurationsAction {
  type: typeof UPDATE_CONFIGURATIONS;
  texts: Configuration[];
}

export interface UpdateConfigurationsErrorAction {
  type: typeof UPDATE_CONFIGURATIONS_ERROR;
  error: string;
}

export type UpdateConfigurationsActionTypes =
  | UpdatingConfigurationsAction
  | UpdateConfigurationsAction
  | UpdateConfigurationsErrorAction;

export type ConfigurationsActionTypes = GetConfigurationsActionTypes | UpdateConfigurationsActionTypes;

//#endregion

//#region Instruments actions

export const GETTING_INSTRUMENTS_URL = "GETTING_INSTRUMENTS_URL";
export const ADD_INSTRUMENTS_URL = "ADD_INSTRUMENTS_URL";
export const INSTRUMENTS_URL_ERROR = "INSTRUMENTS_URL_ERROR";

export interface GettingInstrumentsUrlAction {
  type: typeof GETTING_INSTRUMENTS_URL;
}

export interface AddInstrumentsUrlAction {
  type: typeof ADD_INSTRUMENTS_URL;
  url: string;
}

export interface GetInstrumentsUrlErrorAction {
  type: typeof INSTRUMENTS_URL_ERROR;
  error: string;
}

export type GetInstrumentsUrlActionTypes =
  | GettingInstrumentsUrlAction
  | AddInstrumentsUrlAction
  | GetInstrumentsUrlErrorAction;

export const GETTING_INSTRUMENT = "GETTING_INSTRUMENT";
export const ADD_INSTRUMENT = "ADD_INSTRUMENT";
export const INSTRUMENT_ERROR = "INSTRUMENT_ERROR";

export interface GettingInstrumentAction {
  type: typeof GETTING_INSTRUMENT;
}

export interface AddInstrumentAction {
  type: typeof ADD_INSTRUMENT;
  instrument: Instrument;
}

export interface GetInstrumentErrorAction {
  type: typeof INSTRUMENT_ERROR;
  error: string;
}

export type GetInstrumentActionTypes = GettingInstrumentAction | AddInstrumentAction | GetInstrumentErrorAction;

export type InstrumentsActionTypes = GetInstrumentsUrlActionTypes | GetInstrumentActionTypes;

//#endregion

//#region Subscription types

export const GETTING_SUBSCRIPTION_TYPES = "GETTING_SUBSCRIPTION_TYPES";
export const ADD_SUBSCRIPTION_TYPES = "ADD_SUBSCRIPTION_TYPES";
export const SUBSCRIPTION_TYPES_ERROR = "SUBSCRIPTION_TYPES_ERROR";

export interface GettingSubscriptionTypesAction {
  type: typeof GETTING_SUBSCRIPTION_TYPES;
}

export interface AddSubscriptionTypesAction {
  type: typeof ADD_SUBSCRIPTION_TYPES;
  subscriptionTypes: SubscriptionType[];
}

export interface GetSubscriptionTypesErrorAction {
  type: typeof SUBSCRIPTION_TYPES_ERROR;
  error: string;
}

export const SELECT_SUBSCRIPTION_TYPE = "SELECT_SUBSCRIPTION_TYPE";

export interface SelectSubscriptionTypeAction {
  type: typeof SELECT_SUBSCRIPTION_TYPE;
  subscriptionType: SubscriptionType;
}

export type GetSubscriptionTypesActionTypes =
  | GettingSubscriptionTypesAction
  | AddSubscriptionTypesAction
  | GetSubscriptionTypesErrorAction;

export type SubscriptionTypesActionTypes = GetSubscriptionTypesActionTypes | SelectSubscriptionTypeAction;

//#endregion

//#region SupportedIssuers

export const GETTING_SUPPORTED_ISSUERS = "GETTING_SUPPORTED_ISSUERS";
export const ADD_SUPPORTED_ISSUERS = "ADD_SUPPORTED_ISSUERS";
export const SUPPORTED_ISSUERS_ERROR = "SUPPORTED_ISSUERS_ERROR";

export interface GettingSupportedIssuersAction {
  type: typeof GETTING_SUPPORTED_ISSUERS;
}

export interface AddSupportedIssuersAction {
  type: typeof ADD_SUPPORTED_ISSUERS;
  supportedIssuers: Issuer[];
}

export interface GetSupportedIssuersErrorAction {
  type: typeof SUPPORTED_ISSUERS_ERROR;
  error: string;
}

export type GetSupportedIssuersActionTypes =
  | GettingSupportedIssuersAction
  | AddSupportedIssuersAction
  | GetSupportedIssuersErrorAction;

//#endregion

//#region Subscriptors

export const CREATING_SUBSCRIPTOR = "CREATING_SUBSCRIPTOR";
export const CREATE_SUBSCRIPTOR_SUCCESS = "CREATE_SUBSCRIPTOR_SUCCESS";
export const CREATE_SUBSCRIPTOR_ERROR = "CREATE_SUBSCRIPTOR_ERROR";

export interface CreatingSubscriptorAction {
  type: typeof CREATING_SUBSCRIPTOR;
}

export interface CreateSubscriptorSuccessAction {
  type: typeof CREATE_SUBSCRIPTOR_SUCCESS;
}

export interface CreateSubscriptorErrorAction {
  type: typeof CREATE_SUBSCRIPTOR_ERROR;
  error: string;
}

export type CreateSubscriptorActionTypes =
  | CreatingSubscriptorAction
  | CreateSubscriptorSuccessAction
  | CreateSubscriptorErrorAction;

export const SUBSCRIBING = "SUBSCRIBING";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_ERROR = "SUBSCRIBE_ERROR";
export const RETRY_SUBSCRIPTION = "RETRY_SUBSCRIPTION";
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";

export interface SubscribingAction {
  type: typeof SUBSCRIBING;
  subscribeRequest: SubscribeRequest;
}

export interface SubscribeSuccessAction {
  type: typeof SUBSCRIBE_SUCCESS;
}

export interface SubscribeErrorAction {
  type: typeof SUBSCRIBE_ERROR;
  error: string;
}

export interface RetrySubscriptionAction {
  type: typeof RETRY_SUBSCRIPTION;
}

export interface ChangePaymentMethodAction {
  type: typeof CHANGE_PAYMENT_METHOD;
}

export type SubscribeActionTypes =
  | SubscribingAction
  | SubscribeSuccessAction
  | SubscribeErrorAction
  | RetrySubscriptionAction
  | ChangePaymentMethodAction;

export const GETTING_SUBSCRIPTORS = "GETTING_SUBSCRIPTORS";
export const ADD_SUBSCRIPTORS = "ADD_SUBSCRIPTORS";
export const SUBSCRIPTORS_ERROR = "SUBSCRIPTORS_ERROR";

export interface GettingSubscriptorsAction {
  type: typeof GETTING_SUBSCRIPTORS;
}

export interface AddSubscriptorsAction {
  type: typeof ADD_SUBSCRIPTORS;
  subscriptors: Subscriptor[];
}

export interface GetSubscriptorsErrorAction {
  type: typeof SUBSCRIPTORS_ERROR;
  error: string;
}

export type GetSubscriptorsActionTypes = GettingSubscriptorsAction | AddSubscriptorsAction | GetSubscriptorsErrorAction;

export const ADD_USER_FIELDS = "ADD_USER_FIELDS";

export interface AddUserFieldsAction {
  type: typeof ADD_USER_FIELDS;
  fields: UserDataObject[];
}

export const ADDING_SUBSCRIPTOR_DATA = "ADDING_SUBSCRIPTOR_DATA";
export const ADD_SUBSCRIPTOR_DATA_SUCCESS = "ADD_SUBSCRIPTOR_DATA_SUCCESS";
export const ADD_SUBSCRIPTOR_DATA_ERROR = "ADD_SUBSCRIPTOR_DATA_ERROR";

export interface AddingSubscriptorDataAction {
  type: typeof ADDING_SUBSCRIPTOR_DATA;
}

export interface AddSubscriptorDataSuccessAction {
  type: typeof ADD_SUBSCRIPTOR_DATA_SUCCESS;
}

export interface AddSubscriptorDataErrorAction {
  type: typeof ADD_SUBSCRIPTOR_DATA_ERROR;
  error: string;
}

export type AddSubscriptionDataActionTypes =
  | AddUserFieldsAction
  | AddingSubscriptorDataAction
  | AddSubscriptorDataSuccessAction
  | AddSubscriptorDataErrorAction;

export type SubscriptorsActionTypes =
  | CreateSubscriptorActionTypes
  | SubscribeActionTypes
  | GetSubscriptorsActionTypes
  | AddSubscriptionDataActionTypes;

//#endregion

export type AppActions =
  | AuthActionTypes
  | InstrumentsActionTypes
  | SubscriptionTypesActionTypes
  | SubscriptorsActionTypes;
