import React, { useState } from "react";

export enum Level {
  Success = 0,
  Warning = 1,
  Info = 2,
  Error = 3,
}

interface SnackbarProps {
  open: boolean;
  close(): void;
  timeout?: number;
  level: Level;
  message: string;
}

function getClassFromLevel(level: Level) {
  switch (level) {
    case Level.Success:
      return "is-success";
    case Level.Warning:
      return "is-warning";
    case Level.Info:
      return "is-info";
    case Level.Error:
      return "is-danger";
  }
}

function Snackbar({ open, close, timeout, level, message }: SnackbarProps) {
  const [fadingOut, setFadingOut] = useState<boolean>(false);
  const fadeOutTime = timeout ? timeout - 900 : 2100;

  if (open) {
    setTimeout(() => {
      setFadingOut(true);
    }, fadeOutTime);

    setTimeout(
      () => {
        close();
      },
      timeout ? timeout : 3000
    );

    return (
      <div className={`fade-in notification ${fadingOut ? "fade-out" : ""} ${getClassFromLevel(level)}`}>{message}</div>
    );
  }

  return null;
}

export default Snackbar;
