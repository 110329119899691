import React, { useReducer, useState } from "react";
import { loginFormReducer, initialState, CHANGE_EMAIL, CHANGE_PASSWORD } from "./reducer";
import { RootState } from "../../types/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { LoginRequest } from "../../types/auth.types";
import { login } from "../../store/action_creators/auth.actions";
import { connect, ConnectedProps } from "react-redux";
import Snackbar, { Level } from "../../components/Snackbar/Snackbar";

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  login: (loginRequest: LoginRequest) => dispatch(login(loginRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Login({ auth, login }: PropsFromRedux) {
  const [logginIn, setLogginIn] = useState<boolean>(false);
  const [state, dispatch] = useReducer(loginFormReducer, initialState);

  const submit = () => {
    const loginRequest: LoginRequest = {
      email: state.email,
      password: state.password,
    };

    setLogginIn(true);
    login(loginRequest);
  };

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: CHANGE_EMAIL,
      email: event.target.value,
    });
  };

  const changePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: CHANGE_PASSWORD,
      password: event.target.value,
    });
  };

  return (
    <div className="login">
      <h1 className="title">Iniciar sesión</h1>
      <div className="login-form">
        <div className="field">
          <div className="control">
            <input
              className="input is-medium"
              value={state.email}
              onChange={changeEmail}
              type="text"
              placeholder="Usuario"
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <input
              className="input is-medium"
              value={state.password}
              onChange={changePassword}
              type="password"
              placeholder="Contraseña"
            />
          </div>
        </div>
        <div className="actions">
          <div className="control">
            <button onClick={submit} className="button is-link is-medium">
              Iniciar sesión
            </button>
          </div>
        </div>
      </div>
      <Snackbar
        open={logginIn && auth.loginErrorMessage !== null}
        close={() => setLogginIn(false)}
        level={Level.Error}
        message="Ocurrió un error al iniciar sesión"
      />
    </div>
  );
}

export default connector(Login);
