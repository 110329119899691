import { get, post } from "./base.service";
import { BASE_URL } from "./config";
import { Instrument, InstrumentsUrlRequest, InstrumentsUrlResponse } from "../types/instruments.types";

export const instrumentsService = {
  getInstrumentsUrl,
  getInstrument,
};

async function getInstrumentsUrl(
  instrumentsUrlRequest: InstrumentsUrlRequest
): Promise<InstrumentsUrlResponse | undefined> {
  return await post<InstrumentsUrlResponse>(`${BASE_URL}/subscription/registerInstrument`, instrumentsUrlRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function getInstrument(): Promise<Instrument | undefined> {
  return await get<Instrument>(`${BASE_URL}/instruments`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
