import { SubscriptorsState } from "../../types/subscriptors.types";
import {
  ADDING_SUBSCRIPTOR_DATA,
  ADD_SUBSCRIPTORS,
  ADD_SUBSCRIPTOR_DATA_ERROR,
  ADD_SUBSCRIPTOR_DATA_SUCCESS,
  ADD_USER_FIELDS,
  CHANGE_PAYMENT_METHOD,
  CREATE_SUBSCRIPTOR_ERROR,
  CREATE_SUBSCRIPTOR_SUCCESS,
  CREATING_SUBSCRIPTOR,
  GETTING_SUBSCRIPTORS,
  LOGOUT,
  LogoutActionTypes,
  SUBSCRIBE_ERROR,
  SUBSCRIBE_SUCCESS,
  SUBSCRIBING,
  SubscriptorsActionTypes,
  SUBSCRIPTORS_ERROR,
} from "../config/ActionTypes";

export const initialState: SubscriptorsState = {
  creatingSubscriptor: false,
  createSubscriptorSuccess: false,
  createSubscriptorErrorMessage: null,
  subscribing: false,
  subscribeRequest: null,
  subscribeSuccess: false,
  subscribeErrorMessage: null,
  gettingSubscriptors: false,
  subscriptors: null,
  subscriptorsErrorMessage: null,
  userFields: null,
  addingSubscriptorData: false,
  addSubscriptorDataSuccess: false,
  addSubscriptorDataErrorMessage: null,
};

export function subscriptorsReducer(
  state = initialState,
  action: SubscriptorsActionTypes | LogoutActionTypes
): SubscriptorsState {
  switch (action.type) {
    case CREATING_SUBSCRIPTOR: {
      return {
        ...state,
        creatingSubscriptor: true,
        createSubscriptorSuccess: false,
        createSubscriptorErrorMessage: null,
      };
    }

    case CREATE_SUBSCRIPTOR_SUCCESS: {
      return {
        ...state,
        creatingSubscriptor: false,
        createSubscriptorSuccess: true,
        createSubscriptorErrorMessage: null,
      };
    }

    case CREATE_SUBSCRIPTOR_ERROR: {
      return {
        ...state,
        creatingSubscriptor: false,
        createSubscriptorSuccess: false,
        createSubscriptorErrorMessage: action.error,
      };
    }

    case SUBSCRIBING: {
      return {
        ...state,
        subscribing: true,
        subscribeRequest: action.subscribeRequest,
        subscribeSuccess: false,
        subscribeErrorMessage: null,
      };
    }

    case SUBSCRIBE_SUCCESS: {
      return {
        ...state,
        subscribing: false,
        subscribeSuccess: true,
        subscribeErrorMessage: null,
      };
    }

    case SUBSCRIBE_ERROR: {
      return {
        ...state,
        subscribing: false,
        subscribeSuccess: false,
        subscribeErrorMessage: action.error,
      };
    }

    case CHANGE_PAYMENT_METHOD: {
      return {
        ...state,
        subscribing: false,
        subscribeSuccess: false,
        subscribeErrorMessage: null,
      };
    }

    case GETTING_SUBSCRIPTORS: {
      return {
        ...state,
        gettingSubscriptors: true,
        subscriptors: null,
        subscriptorsErrorMessage: null,
      };
    }

    case ADD_SUBSCRIPTORS: {
      return {
        ...state,
        gettingSubscriptors: false,
        subscriptors: action.subscriptors,
        subscriptorsErrorMessage: null,
      };
    }

    case SUBSCRIPTORS_ERROR: {
      return {
        ...state,
        gettingSubscriptors: false,
        subscriptors: null,
        subscriptorsErrorMessage: action.error,
      };
    }

    case ADD_USER_FIELDS: {
      return {
        ...state,
        userFields: action.fields,
      };
    }

    case ADDING_SUBSCRIPTOR_DATA: {
      return {
        ...state,
        addingSubscriptorData: true,
        addSubscriptorDataSuccess: false,
        addSubscriptorDataErrorMessage: null,
      };
    }

    case ADD_SUBSCRIPTOR_DATA_SUCCESS: {
      return {
        ...state,
        addingSubscriptorData: false,
        addSubscriptorDataSuccess: true,
        addSubscriptorDataErrorMessage: null,
      };
    }

    case ADD_SUBSCRIPTOR_DATA_ERROR: {
      return {
        ...state,
        addingSubscriptorData: false,
        addSubscriptorDataSuccess: false,
        addSubscriptorDataErrorMessage: action.error,
      };
    }

    case LOGOUT: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
