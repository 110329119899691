import React from "react";
import Loading from "../../components/Loading";

function LoadingScreen() {
  return (
    <div className="loading">
      <Loading />
    </div>
  );
}

export default LoadingScreen;
