import { UserDataObject, SubscriptorFieldEnum } from "../types/subscriptors.types";

export const formHelper = {
  mustShowField,
};

function mustShowField(field: SubscriptorFieldEnum, fields: UserDataObject[]): boolean {
  var show = true;

  fields.forEach((f) => {
    if (f.field === field) {
      show = !f.exist;
    }
  });

  return show;
}
