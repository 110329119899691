import { SubscriptionTypesState } from "../../types/subscriptionTypes.types";
import {
  ADD_SUBSCRIPTION_TYPES,
  GETTING_SUBSCRIPTION_TYPES,
  LOGOUT,
  LogoutActionTypes,
  SELECT_SUBSCRIPTION_TYPE,
  SubscriptionTypesActionTypes,
  SUBSCRIPTION_TYPES_ERROR,
} from "../config/ActionTypes";

export const initialState: SubscriptionTypesState = {
  gettingSubscriptionTypes: false,
  subscriptionTypes: null,
  subscriptionTypesErrorMessage: null,
  selectedSubscriptionType: null,
};

export function subscriptionTypesReducer(
  state = initialState,
  action: SubscriptionTypesActionTypes | LogoutActionTypes
): SubscriptionTypesState {
  switch (action.type) {
    case GETTING_SUBSCRIPTION_TYPES:
      return {
        ...state,
        gettingSubscriptionTypes: true,
        subscriptionTypes: null,
        subscriptionTypesErrorMessage: null,
      };

    case ADD_SUBSCRIPTION_TYPES:
      return {
        ...state,
        gettingSubscriptionTypes: false,
        subscriptionTypes: action.subscriptionTypes,
        subscriptionTypesErrorMessage: null,
      };

    case SUBSCRIPTION_TYPES_ERROR:
      return {
        ...state,
        gettingSubscriptionTypes: false,
        subscriptionTypes: null,
        subscriptionTypesErrorMessage: action.error,
      };

    case SELECT_SUBSCRIPTION_TYPE:
      return {
        ...state,
        selectedSubscriptionType: action.subscriptionType,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
