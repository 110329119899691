import { AuthState } from "../../types/auth.types";
import { AuthActionTypes, LOGGING_IN, LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT } from "../config/ActionTypes";

export const initialState: AuthState = {
  loggingIn: false,
  loggedIn: false,
  loginErrorMessage: null,
};

export function authReducer(state = initialState, action: AuthActionTypes): AuthState {
  switch (action.type) {
    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
        loggedIn: false,
        loginErrorMessage: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loggingIn: false,
        loggedIn: true,
        loginErrorMessage: null,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        loggingIn: false,
        loggedIn: false,
        loginErrorMessage: action.error,
      };

    case LOGOUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
