import { getGenderString, Subscriptor } from "../types/subscriptors.types";

export const xlsHelper = {
  getUsersXLS,
};

function getUsersXLS(users: Subscriptor[]) {
  if (users) {
    let newData = users.map((u) => {
      return {
        "Id externo": `${u.id ? u.id : "-"}`,
        Nombre: `${u.names ? u.names : "-"} ${u.surnames ? u.surnames : ""}`,
        Email: `${u.email ? u.email : "-"}`,
        "Fecha de nacimiento": `${
          new Date(u.birthDate).toLocaleDateString() !== "1/1/1" ? new Date(u.birthDate).toLocaleDateString() : "-"
        }`,
        Celular: `${u.telephoneNo ? u.telephoneNo : "-"}`,
        Ciudad: `${u.city ? u.city : "-"}`,
        Género: `${getGenderString(u.gender)}`,
        Monto: `${u.amount ? u.amount : "-"}`,
      };
    });

    return newData;
  } else {
    return [];
  }
}
