export const CHANGE_TEXT_ONE = "CHANGE_TEXT_ONE";
export const CHANGE_TEXT_TWO = "CHANGE_TEXT_TWO";

interface ChangeTextOneAction {
  type: typeof CHANGE_TEXT_ONE;
  textOne: string;
}

interface ChangeTextTwoAction {
  type: typeof CHANGE_TEXT_TWO;
  textTwo: string;
}

type ConfigurationFormActions = ChangeTextOneAction | ChangeTextTwoAction;

interface ConfigurationFormState {
  textOne: string;
  textTwo: string;
}

export const initialState: ConfigurationFormState = {
  textOne: "",
  textTwo: "",
};

export function configurationFormReducer(state: ConfigurationFormState, action: ConfigurationFormActions) {
  switch (action.type) {
    case CHANGE_TEXT_ONE: {
      return {
        ...state,
        textOne: action.textOne,
      };
    }

    case CHANGE_TEXT_TWO: {
      return {
        ...state,
        textTwo: action.textTwo,
      };
    }

    default:
      throw new Error();
  }
}
