import { get, post, put } from "./base.service";
import { BASE_URL } from "./config";
import {
  CreateSubscriptorRequest,
  SubscribeRequest,
  Subscriptor,
  SubscriptorData,
  SubscriptorsResponse,
} from "../types/subscriptors.types";
import { BaseResponse } from "../types/base.types";

export const subscriptorsService = {
  createSubscriptor,
  subscribe,
  fetchSubscriptors,
  addSubscriptorData,
};

async function createSubscriptor(
  createSubscriptorRequest: CreateSubscriptorRequest
): Promise<BaseResponse | undefined> {
  return await post<BaseResponse>(`${BASE_URL}/subscriptors`, createSubscriptorRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function subscribe(subscribeRequest: SubscribeRequest): Promise<BaseResponse | undefined> {
  return await post<BaseResponse>(`${BASE_URL}/subscription`, subscribeRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function fetchSubscriptors(): Promise<SubscriptorsResponse | undefined> {
  return await get<SubscriptorsResponse>(`${BASE_URL}/subscriptor`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}

async function addSubscriptorData(subscriptorData: SubscriptorData): Promise<Subscriptor | undefined> {
  return await put<Subscriptor>(`${BASE_URL}/subscriptor`, subscriptorData)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
