import { get } from "./base.service";
import { BASE_URL } from "./config";
import { SubscriptionTypesResponse } from "../types/subscriptionTypes.types";

export const subscriptionTypesService = {
  fetchSubscriptionTypes,
};

async function fetchSubscriptionTypes(): Promise<SubscriptionTypesResponse | undefined> {
  return await get<SubscriptionTypesResponse>(`${BASE_URL}/subscription/subscriptionTypes`)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
