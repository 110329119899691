import { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import TagManager from "react-gtm-module";
import Configuration from "./Configuration/Configuration";
import Landing from "./Landing/Landing";
import Login from "./Login/Login";
import PlexoPayment from "./PlexoPayment/PlexoPayment";
import Result from "./Result/Result";
import Thanks from "./Thanks/Thanks";
import Redirect from "./Redirect/Redirect";
import Users from "./Users/Users";
import { urlHelper } from "../helpers/urlHelper";
import Header from "../components/Header";
import AuthHandler from "../components/AuthHandler";
import LoadingScreen from "./LoadingScreen/LoadingScreen";

const GTM_ID = process.env.REACT_APP_GTM_ID;

const tagManagerArgs = {
  dataLayer: { page: window.location.pathname.replace("/", "") },
  dataLayerName: `${window.location.pathname.replace("/", "")}_dataLayer`,
};

const tagManagerConfig = { gtmId: GTM_ID! };

TagManager.initialize(tagManagerConfig);

function Main() {
  const history = useHistory();
  const [isPrivatePage, setIsPrivatePage] = useState<boolean>(urlHelper.isPrivatePage(history.location.pathname));
  const [image, setImage] = useState<string>("unset");
  const isLogin = history.location.pathname === "/login";

  const randomizeImage = () => {
    const random = Math.floor(Math.random() * 10) % 4;
    setImage(`background-${random + 1}`);
  };

  useEffect(() => {
    if (image === "unset") {
      randomizeImage();
    }
  });

  useEffect(() => TagManager.dataLayer(tagManagerArgs), []);

  return (
    <div className={`main ${image} ${isPrivatePage ? "private-main" : ""}`}>
      <AuthHandler isPrivatePage={isPrivatePage} setIsPrivatePage={setIsPrivatePage} />
      {isPrivatePage && <Header isLoginPage={isLogin} />}
      <div className={`content ${isLogin ? "login-content" : ""}`}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/configuration" component={Configuration} />
          <Route path="/plexo" component={PlexoPayment} />
          <Route path="/result" component={Result} />
          <Route path="/thanks" component={Thanks} />
          <Route path="/users" component={Users} />
          <Route path="/redirect" component={Redirect} />
          <Route path="/loading" component={LoadingScreen} />
          <Route path="/" component={Landing} />
        </Switch>
      </div>
    </div>
  );
}

export default Main;
