import { Dispatch } from "redux";
import { supportedIssuersService } from "../../services/supportedIssuers.service";
import { Issuer } from "../../types/supportedIssuers.types";
import {
  GETTING_SUPPORTED_ISSUERS,
  ADD_SUPPORTED_ISSUERS,
  SUPPORTED_ISSUERS_ERROR,
  GetSupportedIssuersActionTypes
} from "../config/ActionTypes";

//#region Fetch subscription types

export const fetchSupportedIssuers = () => (dispatch: Dispatch<GetSupportedIssuersActionTypes>) => {
  dispatch(gettingSupportedIssuers());

  return supportedIssuersService.fetchSupportedIssuers().then(
    (response) => {
      dispatch(addSupportedIssuers(response!.issuers));
    },
    (error) => {
      dispatch(supportedIssuersError(error));
    }
  );
};

const gettingSupportedIssuers = (): GetSupportedIssuersActionTypes => ({
  type: GETTING_SUPPORTED_ISSUERS,
});

const addSupportedIssuers = (supportedIssuers: Issuer[]): GetSupportedIssuersActionTypes => ({
  type: ADD_SUPPORTED_ISSUERS,
  supportedIssuers,
});

const supportedIssuersError = (error: string): GetSupportedIssuersActionTypes => ({
  type: SUPPORTED_ISSUERS_ERROR,
  error,
});

//#endregion

