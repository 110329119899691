import { Dispatch } from "redux";
import { subscriptionTypesService } from "../../services/subscriptionTypes.service";
import { SubscriptionType } from "../../types/subscriptionTypes.types";
import {
  ADD_SUBSCRIPTION_TYPES,
  GetSubscriptionTypesActionTypes,
  GETTING_SUBSCRIPTION_TYPES,
  SelectSubscriptionTypeAction,
  SELECT_SUBSCRIPTION_TYPE,
  SUBSCRIPTION_TYPES_ERROR,
} from "../config/ActionTypes";

//#region Fetch subscription types

export const fetchSubscriptionTypes = () => (dispatch: Dispatch<GetSubscriptionTypesActionTypes>) => {
  dispatch(gettingSubscriptionTypes());

  return subscriptionTypesService.fetchSubscriptionTypes().then(
    (response) => {
      dispatch(addSubscriptionTypes(response!.subscriptionTypes));
    },
    (error) => {
      dispatch(subscriptionTypesError(error));
    }
  );
};

const gettingSubscriptionTypes = (): GetSubscriptionTypesActionTypes => ({
  type: GETTING_SUBSCRIPTION_TYPES,
});

const addSubscriptionTypes = (subscriptionTypes: SubscriptionType[]): GetSubscriptionTypesActionTypes => ({
  type: ADD_SUBSCRIPTION_TYPES,
  subscriptionTypes,
});

const subscriptionTypesError = (error: string): GetSubscriptionTypesActionTypes => ({
  type: SUBSCRIPTION_TYPES_ERROR,
  error,
});

//#endregion

//#region Select subscription type

export const selectSubscriptionType = (subscriptionType: SubscriptionType): SelectSubscriptionTypeAction => ({
  type: SELECT_SUBSCRIPTION_TYPE,
  subscriptionType,
});

//#endregion
