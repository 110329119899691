import { post } from "./base.service";
import { BASE_URL } from "./config";
import { LoginRequest, LoginResponse } from "../types/auth.types";

export const authService = {
  login,
};

async function login(loginRequest: LoginRequest): Promise<LoginResponse | undefined> {
  return await post<LoginResponse>(`${BASE_URL}/auth/login`, loginRequest)
    .then((response) => {
      return response.parsedBody;
    })
    .catch((error) => {
      throw error;
    });
}
