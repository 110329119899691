import { useReducer } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import { CHANGE_EMAIL } from "../../screens/Login/reducer";
import { addSubscriptorData } from "../../store/action_creators/subscriptors.actions";
import { AppActions } from "../../store/config/ActionTypes";
import { Gender, SubscriptorData, SubscriptorFieldEnum } from "../../types/subscriptors.types";
import { RootState } from "../../types/types";
import { formHelper } from "../../helpers/formHelper";
import Loading from "../Loading";
import {
  initialState,
  userFormReducer,
  CHANGE_DATE,
  CHANGE_CITY,
  CHANGE_GENDER,
  CHANGE_PHONE,
  CHANGE_NAME,
  City,
  getCityString,
} from "./reducer";

const mapStateToProps = (state: RootState) => ({
  subscriptors: state.subscriptors,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  addSubscriptorData: (subscriptorData: SubscriptorData) => dispatch(addSubscriptorData(subscriptorData)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function UserForm({ subscriptors, addSubscriptorData }: PropsFromRedux) {
  const history = useHistory();
  const [state, dispatch] = useReducer(userFormReducer, initialState);

  const finish = () => {
    if (subscriptors.subscribeRequest) {
      const subscriptorData: SubscriptorData = {
        id: subscriptors.subscribeRequest.externalId,
        names: state.name !== "" ? state.name : null,
        surnames: "",
        email: state.email !== "" ? state.email : null,
        city: state.city !== City.None ? state.city.toString() : null,
        birthdate:
          new Date(state.date).getFullYear() === new Date().getFullYear() ? null : new Date(state.date).toUTCString(),
        gender: state.gender !== Gender.Unanswered ? state.gender : null,
        telephoneNo: state.phone !== "" ? state.phone : null,
      };

      addSubscriptorData(subscriptorData);

      console.log(state);
      history.push("/thanks");
    }
  };

  const changeName = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: CHANGE_NAME,
      name: event.target.value,
    });
  };

  const changeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: CHANGE_EMAIL,
      email: event.target.value,
    });
  };

  const changeDate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    console.log(event.target.value);
    dispatch({
      type: CHANGE_DATE,
      date: event.target.value,
    });
  };

  const changePhone = (event: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch({
      type: CHANGE_PHONE,
      phone: event.target.value,
    });
  };

  const changeCity = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    dispatch({
      type: CHANGE_CITY,
      city: Number(event.target.value as any),
    });
  };

  const changeGender = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    dispatch({
      type: CHANGE_GENDER,
      gender: Number(event.target.value as any),
    });
  };

  const cityKeys = Object.keys(City).filter((k) => typeof City[k as any] === "number");

  const showField = (field: SubscriptorFieldEnum) => {
    if (subscriptors.userFields !== null) {
      const ret = formHelper.mustShowField(field, subscriptors.userFields);
      return ret;
    }

    return false;
  };

  if (subscriptors.userFields === null) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  }

  return (
    <div className="user-form">
      <div className="row">
        {showField(SubscriptorFieldEnum.Names) && (
          <div className="field">
            <div className="control">
              <input className="input" value={state.name} onChange={changeName} type="text" placeholder="Nombre" />
            </div>
          </div>
        )}
        {showField(SubscriptorFieldEnum.Email) && (
          <div className="field">
            <div className="control">
              <input className="input" value={state.email} onChange={changeEmail} type="email" placeholder="Email" />
            </div>
          </div>
        )}
        {showField(SubscriptorFieldEnum.Birthdate) && (
          <div className="field">
            <div className="control">
              <input
                className="input"
                value={state.date}
                onChange={changeDate}
                type="date"
                placeholder="Fecha de nacimiento"
              />
            </div>
          </div>
        )}
        {showField(SubscriptorFieldEnum.TelephoneNo) && (
          <div className="field">
            <div className="control">
              <input className="input" value={state.phone} onChange={changePhone} type="text" placeholder="Celular" />
            </div>
          </div>
        )}
        {showField(SubscriptorFieldEnum.City) && (
          <div className="field">
            <div className="control select">
              <select onChange={changeCity}>
                {cityKeys.map((ck) => {
                  return (
                    <option
                      value={City[ck as any]}
                      disabled={(City[ck as any] as any) === City.None}
                      selected={(City[ck as any] as any) === City.None}
                    >
                      {getCityString(City[ck as any] as any)}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
        {showField(SubscriptorFieldEnum.Gender) && (
          <div className="field">
            <div className="control select">
              <select onChange={changeGender}>
                <option value={Gender.Unanswered} disabled selected>
                  Género
                </option>
                <option value={Gender.Female}>Femenino</option>
                <option value={Gender.Male}>Masculino</option>
                <option value={Gender.Other}>Otro</option>
              </select>
            </div>
          </div>
        )}
      </div>
      <div className="form-actions">
        <div className="control">
          <button onClick={finish} className="button is-link is-medium">
            Finalizar
          </button>
        </div>
        <div className="control">
          <button onClick={finish} className="button is-link is-inverted is-small">
            Omitir este paso
          </button>
        </div>
      </div>
    </div>
  );
}

export default connector(UserForm);
