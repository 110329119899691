import { useEffect } from "react";
import { ThunkDispatch } from "redux-thunk";
import { uuid } from "uuidv4";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../types/types";
import { SubscribeRequest } from "../types/subscriptors.types";
import { AppActions } from "../store/config/ActionTypes";
import { subscribe } from "../store/action_creators/subscriptors.actions";
import { useHistory } from "react-router";

const mapStateToProps = (state: RootState) => ({
  subscriptors: state.subscriptors,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  subscribe: (subscribeRequest: SubscribeRequest) => dispatch(subscribe(subscribeRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface SubscribeProps {
  externalId: string;
  subscriptionTypeId: string;
  mustSubscribe: boolean;
}

type PropsType = PropsFromRedux & SubscribeProps;

function Subscribe({ externalId, subscriptionTypeId, mustSubscribe, subscribe, subscriptors }: PropsType) {
  const history = useHistory();

  const isTest =
    window.location.host === "localhost:3000" ||
    window.location.host === "plinks.netlify.app" ||
    window.location.host === "plinks.handsoft.com.uy";
  const environmentId = isTest ? "1snn5n9w" : "k8vif92e";
  const commerceId = isTest ? "123" : "visanetuy_px_2048256716";
  const fingerprintId = uuid();
  const visaFingerprint = commerceId + fingerprintId;
  let fingerprint = isTest ? "d1cab7e203194d09972b7fb3d4657fc8" : fingerprintId;

  const loadVisaDeviceFingerprint = (callback: any) => {
    const existingScript = document.getElementById("visaDeviceFingerprint");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://h.online-metrix.net/fp/tags.js?org_id=${environmentId}&session_id=${visaFingerprint}`;
      script.id = "visaDeviceFingerprint";
      document.body.appendChild(script);

      script.onload = () => {
        if (callback) callback();
      };
    }

    if (existingScript && callback) callback();
  };

  useEffect(() => {
    loadVisaDeviceFingerprint(() => {});
  });

  useEffect(() => {
    if (
      mustSubscribe &&
      !subscriptors.subscribing &&
      !subscriptors.subscribeSuccess &&
      subscriptors.subscribeErrorMessage === null
    ) {
      if (externalId !== "" && subscriptionTypeId !== "") {
        const request: SubscribeRequest = {
          cibersourceFingerprint: fingerprint,
          externalId,
          subscriptionTypeId,
          legalId: "reLegal",
        };

        subscribe(request);
      }
    }
  }, [
    subscriptors.subscribing,
    subscriptors.subscribeSuccess,
    subscriptors.subscribeErrorMessage,
    fingerprint,
    subscribe,
    externalId,
    subscriptionTypeId,
    mustSubscribe,
  ]);

  useEffect(() => {
    if (!subscriptors.subscribing && (subscriptors.subscribeSuccess || subscriptors.subscribeErrorMessage !== null)) {
      history.push("/result");
    }
  });

  return <></>;
}

export default connector(Subscribe);
