
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../store/config/ActionTypes";
import { RootState } from "../types/types";
import { connect, ConnectedProps } from "react-redux";
import { useEffect } from "react";
import { fetchSupportedIssuers } from "../store/action_creators/supportedIssuers.actions";

const mapStateToProps = (state: RootState) => ({
  supportedIssuers: state.supportedIssuers
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  fetchSupportedIssuers: () => dispatch(fetchSupportedIssuers()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function SupportedIssuers( {supportedIssuers, fetchSupportedIssuers} : PropsFromRedux ) {
  useEffect(() => {
    if (
      !supportedIssuers.gettingSupportedIssuers &&
      supportedIssuers.supportedIssuers === null &&
      supportedIssuers.supportedIssuersErrorMessage === null
    ) {
      fetchSupportedIssuers();
    }
  }, [
    supportedIssuers.gettingSupportedIssuers,
    supportedIssuers.supportedIssuers,
    supportedIssuers.supportedIssuersErrorMessage,
    fetchSupportedIssuers,
  ]);

  return (
    <div className="payment-methods">
      <div>Medios de pago disponibles:</div>
      {supportedIssuers.supportedIssuers &&
        supportedIssuers.supportedIssuers.map((supportedIssuer) => {
          if (supportedIssuer.active)
            return (
              <img src={supportedIssuer.issuerImageUrl} alt={supportedIssuer.normalizedName} key={supportedIssuer.id}/>
            )
          return null;
        }
      )}
    </div>
  )
}

export default connector(SupportedIssuers);