import { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router";
import { ThunkDispatch } from "redux-thunk";
import Cookies from "universal-cookie";
import { urlHelper } from "../helpers/urlHelper";
import { getConfigurations } from "../store/action_creators/configurations.actions";
import { AppActions } from "../store/config/ActionTypes";
import { RootState } from "../types/types";

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  configurations: state.configurations,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  getConfigurations: () => dispatch(getConfigurations()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface AuthHandlerProps {
  isPrivatePage: boolean;
  setIsPrivatePage(value: boolean): void;
}

type PropsType = PropsFromRedux & AuthHandlerProps;

function AuthHandler({ isPrivatePage, setIsPrivatePage, auth, configurations, getConfigurations }: PropsType) {
  const cookies = new Cookies();
  const history = useHistory();
  const hasToken = cookies.get("token") !== undefined;

  useEffect(() => {
    return history.listen((location) => {
      const path = location.pathname;
      const isPrivate = urlHelper.isPrivatePage(path);
      setIsPrivatePage(isPrivate);
    });
  });

  useEffect(() => {
    if (
      !configurations.gettingConfigurations &&
      configurations.configurations === null &&
      configurations.getConfigurationsErrorMessage === null
    ) {
      getConfigurations();
    }
  });

  useEffect(() => {
    console.log(cookies.get("token"));
    if (hasToken) {
      if (!auth.loggedIn && !auth.loggingIn && auth.loginErrorMessage === null) {
        //TODO: Get account or call loginSuccess
      }

      if (history.location.pathname === "/login") {
        setIsPrivatePage(true);
        history.push("/users");
      }
    } else {
      if (isPrivatePage) {
        history.push("/login");
        setIsPrivatePage(false);
      }
    }
  }, [hasToken, auth.loggedIn, auth.loggingIn, auth.loginErrorMessage, history, isPrivatePage, setIsPrivatePage]);

  return <></>;
}

export default connector(AuthHandler);
