import { SubscriptionType } from "../../types/subscriptionTypes.types";

interface DonationOptionProps {
  subscriptionType: SubscriptionType;
  onOptionClick(subscriptionType: SubscriptionType): void;
}

function DonationOption({ subscriptionType, onOptionClick }: DonationOptionProps) {
  return (
    <div className="column option" onClick={() => onOptionClick(subscriptionType)}>
      <div className="option-content">
        <span className="donate">Donar</span>
        <p>
          <span className="amount">{`$${subscriptionType.subscriptionBaseCost}`}</span>
        </p>
      </div>
    </div>
  );
}

export default DonationOption;
