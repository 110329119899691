import * as signalR from "@aspnet/signalr";
import * as ActionTypes from "../config/ActionTypes";
import { urlHelper } from "../../helpers/urlHelper";
import { Middleware } from "redux";
import { RootState } from "../../types/types";
import { UserDataObject } from "../../types/subscriptors.types";
import { addUserFields } from "../action_creators/subscriptors.actions";

const startSignalRConnection = async (connection: signalR.HubConnection, externalId: string, message: string) => {
  return await connection
    .start()
    .then(() => {
      console.log("signalR connection success");
      connection.send(message, externalId);
      return connection;
    })
    .catch((error) => {
      console.log("signalR error: " + error);
    });
};

const signalRMiddleware: Middleware<
  {}, // legacy type parameter added to satisfy interface signature
  RootState
> = (store) => (next) => (action) => {
  const connectionHub = urlHelper.getHubUrl();
  const protocol = new signalR.JsonHubProtocol();
  //const transport = signalR.HttpTransportType.WebSockets;

  const options = {
    //transport,
  };

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(connectionHub, options)
    .configureLogging(signalR.LogLevel.Debug)
    .withHubProtocol(protocol)
    .build();

  //connection.onclose(() => setTimeout(startSignalRConnection(connection), 5000));

  connection.on("UserDataCallback", (fields: UserDataObject[]) => {
    console.log("UserDataCallback received");

    if (fields.length > 0) next(addUserFields(fields));
  });

  if (action.type === ActionTypes.SUBSCRIBING) {
    startSignalRConnection(connection, action.subscribeRequest.externalId, "SuscritonInit");
  }

  //   if (action.type === ActionTypes.VISA_REDIRECTED) {
  // got paymentId from query string
  // console.log("VISA REDIRECTED, starting signalR connection");
  // startSignalRConnection(connection, action.paymentId, "VisaStatus");
  //   }

  return next(action);
};

export default signalRMiddleware;
