import { Lottie } from "@crello/react-lottie";
import successAnimation from "../../assets/suscriton-success.json";
import errorAnimation from "../../assets/suscriton-error.json";
import UserForm from "../../components/UserForm/UserForm";
import { useHistory } from "react-router";
import sociosOnlineLogo from "../../assets/sociosonline.png";
import blackLogo from "../../assets/blackLogo.png";
import { RootState } from "../../types/types";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../../store/config/ActionTypes";
import { connect, ConnectedProps } from "react-redux";
import { changePaymentMethod, subscribe } from "../../store/action_creators/subscriptors.actions";
import { SubscribeRequest } from "../../types/subscriptors.types";

const mapStateToProps = (state: RootState) => ({
  subscriptors: state.subscriptors,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, any, AppActions>) => ({
  changePaymentMethod: () => dispatch(changePaymentMethod()),
  subscribe: (subscribeRequest: SubscribeRequest) => dispatch(subscribe(subscribeRequest)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

function Result({ subscriptors, changePaymentMethod, subscribe }: PropsFromRedux) {
  const history = useHistory();
  const success = subscriptors.subscribeSuccess;

  const changeCard = () => {
    changePaymentMethod();
    history.push("/plexo");
  };

  const retryPayment = () => {
    if (subscriptors.subscribeRequest) {
      subscribe(subscriptors.subscribeRequest);
    }
  };

  return (
    <div className="result">
      <img className="socios-online" src={sociosOnlineLogo} alt="Logo de Socios Online" />
      <img className="teleton" src={blackLogo} alt="Logo de Teletón" />
      <h1 className="title">{success ? "Donación realizada con éxito" : "Algo salió mal"}</h1>
      <Lottie
        height="10rem"
        width="10rem"
        config={{ animationData: success ? successAnimation : errorAnimation, loop: false }}
      />
      {success && (
        <h3 className="title">
          Ya sos socio/a de Teletón, queremos hacerte unas preguntas para conocerte un poquito más...
        </h3>
      )}
      <p className="plain-text">
        {success
          ? "Tu ayuda es fundamental para que podamos sostener nuestro esfuerzo, y queremos conocer mejor a quienes como tú están apoyando a Teletón. Entender mejor quienes colaboran con la fundación nos permite trabajar en lograr aún más apoyos y llegar a más personas que necesiten de nuestra ayuda."
          : "El pago no pudo realizarse"}
      </p>
      {success ? (
        <UserForm />
      ) : (
        <div className="actions">
          <button onClick={changeCard} className="button is-link is-inverted">
            Cambiar medio de pago
          </button>
          <button onClick={retryPayment} className="button is-link">
            Reintentar
          </button>
        </div>
      )}
    </div>
  );
}

export default connector(Result);
